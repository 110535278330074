<template>
    <v-dialog persistent v-model="show">
        <v-card>
            <v-card-title>Загрузка</v-card-title>
            <v-card-text>
                <p>Файлы: {{ current }} / {{ total }}</p>
                <p>Текущий файл</p>
                <v-progress-linear :value="progress" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template> 

<script>
import Flow from "@flowjs/flow.js";
export default {
    name: "FileUploader",
    data: () => ({
        total: 0,
        current: 0,
        progress: 0,
        show: false,
    }),
    methods: {
        async upload(filesToUpload) {
            if (!filesToUpload.length) {
                return filesToUpload;
            }
            this.total = filesToUpload.length;
            this.current = 0;
            this.progress = 0;
            this.show = true;
            return new Promise((resolve, reject) => {
                let error = false;
                const uploader = new Flow({
                    target: `${process.env.VUE_APP_API}/admin/upload`,
                    allowDuplicateUploads: true,
                    withCredentials: true,
                });
                uploader.on("error", () => {
                    error = true;
                    uploader.cancel();
                });
                uploader.on("fileSuccess", ({ file }, msg) => {
                    this.current += 1;
                    for (let f of filesToUpload) {
                        if (f.id == file.id) {
                            f.tmp = msg;
                        }
                    }
                });
                uploader.on("fileProgress", (file) => {
                    this.progress = file.progress() * 100;
                });
                uploader.on("complete", () => {
                    this.show = false;
                    if (error) {
                        reject(new Error("Ошибка загрузки файлов"));
                        return false;
                    }
                    resolve(filesToUpload);
                });
                uploader.addFiles(filesToUpload);
                uploader.upload();
            });
        },
    },
};
</script>